<template lang="pug">

  transition(
    @enter="enter"
    @leave="leave"
    :css="false"
    appear
  )

    #delivery.module
      .inner
        good-logo-btn
        h1.header {{ lang_en['deliverygoodmeatco-video-landing-page-title'] }}
        div.copy(v-html="isMobile() || isTablet() ? lang_en['deliverygoodmeatco-video-landing-page-intro-paragraph-mobile'] : lang_en['deliverygoodmeatco-video-landing-page-intro-paragraph-desktop']")

        .buttonContainer.watchLink
          a(:href="lang_en['deliverygoodmeatco-video-landing-page-video-url']"
            target="_blank"
            rel="noreferrer noopener"
            @click="onWatchClick"
            ).button {{ lang_en['deliverygoodmeatco-video-landing-page-launch-video-button'] }}
            right-arrow-svg(v-if="isDesktopOrTablet()")
            cardboard-svg(v-else)

        .appButtons(v-if="isMobile()")
          .buttonContainer.appLink(v-if="isIos()")
            a(:href="lang_en['deliverygoodmeatco-video-landing-page-ios-youtube-app-url']"
              target="_blank"
              rel="noreferrer noopener"
              @click="onIOSClick"
            ).button {{ lang_en['deliverygoodmeatco-video-landing-page-download-youtube-app-button'] }}
              right-arrow-svg
          .buttonContainer.appLink(v-else)
            a(:href="lang_en['deliverygoodmeatco-video-landing-page-android-youtube-app-url']"
              target="_blank"
              rel="noreferrer noopener"
              @click="onAndroidClick"
            ).button {{ lang_en['deliverygoodmeatco-video-landing-page-download-youtube-app-button'] }}
              right-arrow-svg

</template>

<script>
import gsap from 'gsap'
import { mapState, mapMutations, mapGetters } from 'vuex'
import Env from 'src/config/env'
import { PATHS, HOME, MODAL_STATES } from 'src/config/app'
import CardboardSvg from 'src/assets/svgs/cardboard.svg'
import RightArrowSvg from 'src/assets/svgs/right_button_arrow.svg'
import GoodLogoBtn from '@/components/GoodLogoBtn'
import {default as AnalyticsService, GA_EVENT, GA_PAGES} from 'src/services/AnalyticsService'
import device from 'src/libs/device'

export default {
  name: PATHS.HOME,

  components: {
    CardboardSvg,
    GoodLogoBtn,
    RightArrowSvg
  },

  data() {
    return {}
  },

  computed: {},

  beforeMount() {},

  mounted() {
    AnalyticsService.sendPageview(GA_PAGES.PAGE_DELIVERY)

    setTimeout(() => {this.$el.classList.add("mounted")}, 100)
  },

  beforeDestroy() {},

  methods: {
    onIOSClick() {
      AnalyticsService.sendEventObj(GA_EVENT.IOS_CLICK)
    },
    onAndroidClick() {
      AnalyticsService.sendEventObj(GA_EVENT.ANDROID_CLICK)
    },
    onWatchClick() {
      AnalyticsService.sendEventObj(GA_EVENT.WATCH_CLICK)
    },
    onLearnMoreClick() {
      AnalyticsService.sendEventObj(GA_EVENT.LEARN_MORE_CLICK)
    },

    isMobile() {
      return device.mobile()
    },

    isTablet() {
      return device.tablet()
    },

    isDesktopOrTablet() {
      return !device.mobile()
    },

    isIos() {
      return device.ios()
    },

    enter(el, done) {
      done()
    },

    leave(el, done) {
      done()
    },
  },

  watch: {},
}
</script>

<style lang="sass" scoped>
@import "src/styles/common.sass"

::v-deep .good-logo-btn
  opacity: 0
  transition: opacity 2s cubic-bezier(0.37, 0, 0.63, 1)

  .mounted &
    opacity: 1

#delivery
  width: 100%
  min-height: 100%
  margin: 0 0 0 0
  padding-top: 6rem
  padding-bottom: 8.5rem
  pointer-events: all
  background-size: cover
  background-image: url("~src/assets/imgs/desktop-bg.jpg")
  // Focus on the chicken
  background-position: 66% 45%

  +mobile
    padding-top: 2.6rem
    padding-bottom: 6.8rem
    background-size: 100% auto
    background-repeat: no-repeat
    background-image: url("~src/assets/imgs/mobile-bg.jpg")
    background-position: 0 0

h1
  +HaasGrotDispMedium
  font-size: 7.6rem
  margin-top: 5.2rem
  margin-bottom: 1.7rem
  grid-column: 1 / 7
  line-height: 1
  opacity: 0
  // sine easeInOut
  transition: opacity 2s cubic-bezier(0.37, 0, 0.63, 1) 0.5s

  +breakpoint(tablet)
    font-size: 3rem
    grid-column: 1 / 7

  +mobile
    margin-top: 24.4rem

  .mounted &
    opacity: 1

.copy
  +HaasGrotDispLight
  grid-column: 1 / 7
  font-size: 2.6rem
  line-height: 1.35
  opacity: 0
  transition: opacity 2s cubic-bezier(0.37, 0, 0.63, 1) 0.75s

  @media screen and (max-width: 800px)
    grid-column: 1 / 6

  +breakpoint(tablet)
    grid-column: 1 / 7
    font-size: 1.8rem
    line-height: 1.4

  ::v-deep a
    text-decoration: underline

  .mounted &
    opacity: 1

::v-deep p + p
  margin-top: 1.5rem

.downloadCTA
  margin-top: 4rem

.buttonContainer, .appButtons
  grid-column: 1 / 7

.button
  +HaasGrotDispMedium

  display: flex
  width: 33.5rem
  height: 9.5rem
  max-width: 100%
  align-content: center
  justify-content: space-between

  color: white
  cursor: pointer
  border-radius: 0
  font-size: 2.6rem
  border: solid 1px white
  background: rgba(255, 255, 255, 0)
  opacity: 0
  transition: color 0.2s ease, background 0.2s ease, opacity 2s cubic-bezier(0.37, 0, 0.63, 1) 1s
  padding: 3.1rem 3rem 3.1rem 3rem

  -webkit-box-shadow: none
  -moz-box-shadow: none
  box-shadow: none

  +breakpoint(tablet)
    height: 7.7rem
    font-size: 1.8rem
    letter-spacing: 0.04em
    padding: 2.7rem 2.2rem 2.7rem 2.2rem

  .mounted &
    opacity: 1

  svg
    fill: white
    transition: fill 0.2s ease

  .rightArrowSVG
    fill: none
    stroke: white
    width: 3.6rem
    margin-top: 0.4rem
    margin-right: -0.3rem

  .cardboardSVG
    height: 2rem
    width: 3.5rem

  &:hover
    color: black
    background: rgba(255, 255, 255, 1)

    .rightArrowSVG
      fill: none
      stroke: black


.goodmeatLink, .appLink
  a
    margin-top: 6rem

.appLink
  a
    +breakpoint(tablet)
      margin-top: 4rem

.watchLink
  a
    margin-top: 4rem


</style>
