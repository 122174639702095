<template lang="pug">

  transition(
    @enter="enter"
    @leave="leave"
    appear
  )

    a.good-logo-btn(
      href="https://goodmeat.co/"
      @click="onClick"
    )
      .logoInner()
        good-logo-svg

</template>

<script>
import gsap from 'gsap'
import Env from 'src/config/env'
import { mapState, mapMutations } from 'vuex'
import { MODAL_STATES, PATHS } from 'src/config/app'
import GoodLogoSvg from 'src/assets/svgs/GOOD_Logo.svg'
import { clamp, map } from '@/helpers/MathUtils'
import { default as AnalyticsService, GA_EVENT } from 'src/services/AnalyticsService'

export default {
  props: {},

  data() {
    return {}
  },

  mounted() {

  },

  computed: {

    ...mapState('app', ['modal_state']),

  },

  components: {
    GoodLogoSvg,
  },

  watch: {},

  methods: {
    // ...mapMutations('app',[ 'setModalState']),

    onClick(e) {
      AnalyticsService.sendEventObj(GA_EVENT.LOGO_CLICK)
    },

    enter(el, done) {
      done()
    },

    leave(el, done) {
      done()
    },
  },
}
</script>

<style lang="sass">
@import "src/styles/common.sass"


.good-logo-btn
  position: relative
  width: 7.4rem
  height: 12rem
  display: flex
  align-items: center
  justify-content: center
  outline: none

  +mobile
    width: 4rem
    height: 6rem

  .logoInner
    position: absolute
    width: 100%
    height: 100%

    svg
      position: absolute
      display: block
      width: 100%
      height: 100%
      overflow: visible
</style>
